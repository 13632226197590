import {post} from '@/http'

// 新建/编辑收货地址
export function changedAddress(data) {
    return new Promise((resolve, reject) => {
        post('/Address/ChangedAddress', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}
// 我的收货地址
export function getUserAddressList(data) {
    return new Promise((resolve, reject) => {
        post('/Address/GetUserAddressList', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

//  删除收货地址
export function removeAddress(data) {
    return new Promise((resolve, reject) => {
        post('/Address/RemoveAddress', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}






